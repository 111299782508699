
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  font-family: 'Lexend', sans-serif;
  background-color: #d5d5d55c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
}
